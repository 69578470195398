<template>
	<el-dialog title="查看服务请求" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="请求编号">
				<el-input v-model.trim="ruleForm.number" disabled></el-input>
			</el-form-item>
			<el-form-item label="请求用户">
				<el-input v-model.trim="ruleForm.principalName" disabled></el-input>
			</el-form-item>
			<el-form-item label="联系方式" class="w50 mr16">
				<el-input v-model.trim="ruleForm.phoneNumber" disabled></el-input>
			</el-form-item>
			<el-form-item label="家庭成员信息" class="w50 mr0">
				<el-input v-model.trim="ruleForm.familyInfo" disabled></el-input>
			</el-form-item>
			<el-form-item label="家庭住址">
				<el-input v-model.trim="ruleForm.address" disabled></el-input>
			</el-form-item>
			<el-form-item label="要求卫生服务团队" class="w50 mr16">
				<el-input v-model.trim="ruleForm.serverTeam" disabled></el-input>
			</el-form-item>
			<el-form-item label="请求时间" class="w50 mr0">
				<el-date-picker v-model="ruleForm.datetime" disabled type="datetime"> </el-date-picker>
			</el-form-item>
			<!-- <el-form-item label="处理结果" prop="state">
				<el-select v-model="ruleForm.state" @change="changeSelect" :disabled="isShowDetail">
					<el-option label="同意" value="U"></el-option>
					<el-option label="不同意" value="R"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="价格" prop="price" v-if="ruleForm.state == 'U'">
				<el-input v-model.trim="ruleForm.price" :disabled="isShowDetail" type="number"></el-input>
			</el-form-item>
			<el-form-item label="附件" prop="fileList" class="uploadDataList" v-if="ruleForm.state == 'U'">
				<el-upload
					action=""
					multiple
					:before-upload="beforeUpload"
					:http-request="uploadURL"
					:on-error="importError"
					:limit="1"
					:file-list="ruleForm.fileList"
					:disabled="isShowDetail"
				>
					<el-button icon="el-icon-upload2">上传附件(PDF)</el-button>
				</el-upload>
			</el-form-item>
			<el-form-item label="不同意理由" prop="refusalCause" v-if="ruleForm.state == 'R'">
				<el-input v-model.trim="ruleForm.refusalCause" type="textarea" autosize placeholder=" 请输入" :disabled="isShowDetail"></el-input>
			</el-form-item> -->
			<el-form-item class="func-btn">
				<el-button @click="onResetForm('ruleForm')" type="primary">确 定</el-button>
				<!-- <el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button> -->
				<!-- <el-button v-else type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取消</el-button> -->
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	components: {},
	data() {
		return {
			ruleForm: {
				state: '',
				price: '',
				fileList: [],
				refusalCause: '',
			},
			createdVisible: false,
			rules: {
				state: [{ required: true, message: '请选择处理结果', trigger: 'change' }],
				fileList: [{ required: true, message: '请上传附件', trigger: 'change' }],
				refusalCause: [{ required: true, message: '请输入不同意理由', trigger: 'change' }],
				price: [{ required: true, message: '请输入价格', trigger: 'change' }],
			},
			saveLoading: false,
			attachmentInfo: [],
			link: '',
		};
	},
	watch: {},
	props: ['isShowDetail'],
	created() {},
	methods: {
		init(params) {
			this.createdVisible = true;
			this.setRuleForm(params);
			this.getAttachment();
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HEALTH_SERVICE_CONTRACT' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		setRuleForm(data) {
			this.$nextTick(() => {
				this.ruleForm = {
					number: data.applicationNumber || '',
					principalName: data.creator?.title || '',
					phoneNumber: data.healthServiceGroup?.description || '',
					address: data.creator?.description || '',
					familyInfo: data.householdMemberList?.map((v) => v.title).join(',') || '',
					serverTeam: data.healthServiceGroup?.title || '',
					datetime: data.createTime || '',
					state: data.state == 'U' || data.state == 'R' ? data.state : '',
					price: data.price / 100 || '',
					fileList:
						data.attachments?.map((item) => {
							return {
								title: item.title,
								url: item.link,
								uid: item.id,
								name: item.title,
							};
						}) || [],
					refusalCause: data.refusalCause || '',
				};
			});
			this.$forceUpdate();
		},
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						price: this.ruleForm.price * 100,
						attachments: this.ruleForm.fileList.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					console.log(params);
					this.$http
						.put(this.link, params)
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message({
									type: 'success',
									message: '保存成功',
								});
								this.$emit('getList');
								this.createdVisible = false;
							}
						})
						.catch((e) => {});
				}else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		changeSelect(val) {
			this.ruleForm.state = val;
			this.$refs.ruleForm.clearValidate();
		},
		// 自定义上传
		uploadURL(file) {
			let fileName = 'bernieri/zhangl/HEALTH_SERVICE_CONTRACT' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.ruleForm.fileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		// 重置
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.el-range-editor.is-disabled {
		width: 100%;
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		.el-range-input {
			width: 100%;
			background-color: #f4f3f3 !important;
			border-color: #e4e7ed !important;
		}
	}
	.el-upload {
		.el-button {
			margin-left: 0;
		}
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>